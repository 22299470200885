








































































import {
  computed, ref, defineComponent, PropType,
} from '@vue/composition-api';
import FCImage from '~/components/FCImage.vue';
import { FCButton } from '@fc/angie-ui';
import { IStoryblokMedia } from '~/selectors/types';
import { useModal } from '~/composables/useModal';

export interface IReviewGalleryProps {
  component: 'ReviewGallery';
  codeId: string;
  title: string;
  images: IStoryblokMedia[];
}

export default defineComponent({
  name: 'ReviewGallery',
  components: {
    FCImage,
    FCButton,
  },
  inheritAttrs: false,
  props: {
    title: {
      type: String,
      required: false,
      default: 'Customer photos',
    },
    images: {
      type: Array as PropType<(IStoryblokMedia | undefined)[]>,
      default: () => [],
    },
  },
  setup(props: IReviewGalleryProps) {
    const showAll = ref(false);
    const firstImages = computed(() => props.images?.slice(0, 12));
    const remainingImages = computed(() => props.images?.slice(12, props.images.length));
    const areMoreImgs = computed(() => props.images?.length > 12);
    const imagesValid = computed(() => props.images?.length > 0);

    function toggleShowAllImages() {
      showAll.value = !showAll.value;
    }

    return {
      areMoreImgs,
      firstImages,
      remainingImages,
      toggleShowAllImages,
      showAll,
      openModal(image: IStoryblokMedia) {
        if (image && image?.filename) {
          const maxWidth = Math.min(500, Math.floor(window.innerWidth * 0.85));
          const maxHeight = Math.min(700, Math.floor(window.innerHeight * 0.85));

          const { modalShow } = useModal({
            component: () => import('~/components/storyblok/ImageModal/ImageModal.vue'),
            componentProps: image,
            modalProps: {
              componentName: 'ImageModal',
              classes: 'modal-border-radius',
              maxWidth,
              maxHeight,
            },
          });

          modalShow();
        }
      },
      imagesValid,
      getShowCopy: computed(() => (showAll.value ? 'See Less' : 'See All')),
    };
  },
});
