// eslint-disable-next-line import/no-extraneous-dependencies
import { AsyncComponent } from 'vue';
import { SetupContext } from '@vue/composition-api';

interface IModalProps {
  componentName: string;
  resizable?: boolean;
  adaptive?: boolean;
  draggable?: boolean | string;
  scrollable?: boolean;
  focusTrap?: boolean;
  reset?: boolean;
  clickToClose?: boolean;
  transition?: string;
  overlayTransition?: string;
  classes?: string | string[];
  styles?: string | string[] | object;
  width?: number | string;
  height?: number | string;
  minWidth?: number;
  minHeight?: number;
  maxWidth?: number;
  maxHeight?: number;
  shiftX?: number;
  shiftY?: number;
}

interface IModalEvents {
  emit?: SetupContext['emit'];
  openEvent?: {
    name: string;
    payload: any;
  };
  closeEvent?: {
    name: string;
    payload: any;
  };
  closeCallback?: () => void;
}

interface IModalArguments {
  component: AsyncComponent;
  componentProps?: object;
  options?: IModalEvents;
  modalProps: IModalProps;
}

interface IModalMethods {
  modalShow: () => void;
  modalHide: () => void;
}

export function useModal(args: IModalArguments): IModalMethods {
  const {
    component,
    componentProps,
    options,
    modalProps,
  } = args;

  // @ts-expect-error
  // eslint-disable-next-line no-undef
  const { $modal } = useNuxtApp();

  const {
    componentName,
    resizable,
    adaptive = true,
    draggable,
    scrollable,
    focusTrap,
    reset,
    clickToClose,
    transition,
    overlayTransition,
    classes,
    styles,
    width,
    height = 'auto',
    minWidth,
    minHeight,
    maxWidth,
    maxHeight,
    shiftX,
    shiftY,
  } = modalProps;

  function modalShow() {
    $modal.show(
      component,
      componentProps,
      {
        name: componentName,
        resizable,
        adaptive,
        draggable,
        scrollable,
        focusTrap,
        reset,
        clickToClose,
        transition,
        overlayTransition,
        classes,
        styles,
        width,
        height,
        minWidth,
        minHeight,
        maxWidth,
        maxHeight,
        shiftX,
        shiftY,
      },
      {
        opened: () => {
          if (options?.openEvent && options?.emit) {
            options.emit(options.openEvent.name, options.openEvent.payload);
          }
        },
        closed: () => {
          if (options?.closeEvent && options?.emit) {
            options.emit(options.closeEvent.name, options.closeEvent.payload);
          }

          if (options?.closeCallback) {
            options.closeCallback();
          }
        },
      },
    );
  }

  function modalHide() {
    $modal.hide(modalProps.componentName);
  }

  return { modalShow, modalHide };
}
