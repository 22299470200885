
import { defineComponent } from '@vue/composition-api';
import { LinkButton } from '@fc/angie-ui';
import MediaBackground from '~/components/storyblok/MediaBackground/MediaBackground.vue';

export default defineComponent({
  name: 'ConsoleATF',
  components: {
    MediaBackground,
    LinkButton,
  },
  setup() {
    return {
      firstFrameImageUrl: 'https://a.storyblok.com/f/152150/1920x1080/ce7bc7ff29/fc-holiday-2025-sale-first-frame-001.jpg',
      mp4Video: 'https://cdn.joinfightcamp.com/videos/Holiday+ATF_2024_Large_V1.mp4',
      webmVideo: 'https://cdn.joinfightcamp.com/videos/Holiday_ATF_2024_Large_V1.webm',
    };
  },
});
