import { RichtextInstance } from 'storyblok-js-client';
import {
  red,
  blue,
  bluetint,
  eggShell,
  lightgray,
  black,
  darkgray,
  white,
  // @ts-ignore
} from '@fc/angie-ui/dist/styles/_js_exports.scss';
import { IIncludedFC, IStoryblokVideo } from '../selectors/types';

export interface IProduct {
  category: string;
  description?: string;
  fbid?: string;
  fullPrice?: number;
  id: string;
  images?: any[];
  img_url?: string;
  imgUrl?: string;
  item_id?: string;
  marketplace_img_url?: string;
  oldPrice?: number | null;
  price: number;
  text?: string;
  title: string;
  types?: string[];
  selects?: ISelectOptions; // other products using selects
  sold_out?: boolean;
  variantId?: string;
  variants: ISizeVariant[];
  product: {
    price?: number;
  }
}

export interface IBundle {
  discount?: string;
  img_alt: string;
  img_url: string;
  includes: IProduct[];
  item_id: string;
  marketplace_img_url?: string;
  product: IProduct;
  selects?: ISelectOptions[];
  sold_out: boolean;
  text: string;
  title: string;
  types: string[];
}

export interface IVariant {
  caShippingRestricted?: boolean;
  id: string;
  items: {name: string}[];
  price: number;
  pricingDescription: string;
  pricingDetails: string;
  product: IProduct;
  productName: string;
  qty: number;
  shippingRestricted: boolean;
  subscriptionNeeded: boolean;
  type: string;
  sku?: string;
  uid?: string;
}

export type FCProduct = IProduct | IBundle;
export type FCPackageType = 'FightCamp Trackers + Bag' | 'FightCamp Trackers' | 'TrackersBag' | 'Trackers' | 'Console' | 'ConsoleBag' | 'ConsoleTheWorks' | 'ConsoleAllInOne'
export type FCProductType = 'warranty' | 'subs' | 'equip' | 'package'

export interface IPackage {
  title: string;
  src: {
    filename: string;
    alt: string;
  }
}

export interface ISubscription {
  id: string;
  price: number;
  pricingDescription: string;
  pricingDetails: string;
  product: IProduct;
  productName: string;
  qty: number;
  subid: string;
  type: string;
}

export interface IWarranty {
  name: string;
  description: string;
  image: string;
  imageAlt: string;
  product: object;
  itemId: string;
}

interface ISelectOptions {
  hint: string;
  key: string;
  name:string;
  options: [];
}

// types for product in content folder
export interface IFCPackageProperties {
  title: string;
  id: string;
  text: string;
  description:string;
  product: {
    price: number;
    oldPrice?: number | null;
    monthly: number;
    months: number;
  };
  category: string;
  coverImage: string;
  coverImageAlt: string;
  secondaryImage: string;
  secondaryImageAlt: string;
  includes: object[];
  upsell: object[];
  warranty: object;
  bonusOptions: object[] | [];
  selects?: object[];
  variants: ISizeVariant[];
}

// types for product page ATF
export interface IFCPackageData {
  title: string;
  text: string;
  description: string;
  price: number;
  oldPrice: number;
  financing: {
    price: number;
    months: number;
  };
  includes: object[] | [];
  upsell: object[] | [];
  warranty: object;
  id: string;
  selects: object[] | [];
  variants: IVariant[] | [];
  bonusOptions: {id: string}[] | [];
}

interface ISizeVariant {
  keys?: {
    [key: string]: string;
  };
  id: string;
  sku: string | undefined;
}
export type TColorKeys = 'red' | 'blue' | 'bluetint' | 'eggShell' | 'lightgray' | 'black' | 'darkgray' | 'white' | 'transparent';

export const colors: Record<TColorKeys, string> = {
  red,
  blue,
  bluetint,
  eggShell,
  lightgray,
  black,
  darkgray,
  white,
  transparent: 'transparent',
};

export interface IProductVariant {
  id: string;
  uid: string | null;
  sku: string;
  name: string;
}

interface IProductProperties {
  productName: string;
  id: string;
  variants: IProductVariant[];
  pathId: string;
  productType: string;
  tags: string[];
  price: number;
  oldPrice: number | null;
  description: string;
  shortDescription: string;
  isSoldOut: boolean;
  primaryImg: {
    src: string;
    alt: string;
  };
}

export interface ISelectedUpsellProperties extends IAccessoryProperties {
  selectedId: string;
}

export interface IUpsellsProperties {
  gloveUpsells: IAccessoryProperties[];
  upsells: IAccessoryProperties[];
}

export interface IPackageProperties extends IProductProperties {
  includesBag: boolean;
  assets: ({ src: string; alt: string; } | IStoryblokVideo)[]; // TODO add video content as well
  includedProducts: IIncludedFC[];
}

export interface IAccessoryProperties extends IProductProperties {
  assets: ({ src: string; alt: string; } | IStoryblokVideo)[]; // TODO add video content as well
  lifestyleImage: { src: string; alt: string; }
  includesModalCopy?: RichtextInstance | null;
  includedProducts?: any[];
}
